import axios from 'axios'

const getToken = () => {
  try {
    const json = JSON.parse(localStorage.getItem('user'))
    if (json) return json.token
  } catch (e) {
    console.log(e)
  }
}

const getData = async objectId => {
  let responseCode
  const url = `${process.env.REACT_APP_API_BASE_URL}/awm/objecten/${objectId}/waarderingen/hwk`
  const retVal = await axios
    .get(url, { headers: { Authorization: `Bearer ${getToken()}` } })
    .then(data => {
      responseCode = data.status
      return data.data.data
    })
  return { retVal, responseCode }
}

export { getData as getAwmData }
