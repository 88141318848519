import { UserManager, WebStorageStateStore, Log } from 'oidc-client'
import { IDENTITY_CONFIG, METADATA_OIDC } from '../components/Components/OidcComponent/oidcsettings'

export default class AuthService {
  UserManager

  constructor(props) {
    const { dispatch } = props
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    })
    // Logger
    Log.logger = console
    Log.level = Log.DEBUG
    // eslint-disable-next-line no-unused-vars
    this.UserManager.events.addUserLoaded(user => {
      const payloadAction = {
        id: user.profile.sub,
        name: user.profile.login,
        role: user.profile.role,
        email: user.profile.email,
        authorized: true,
        token: user.access_token,
      }
      // todo Nikola
      dispatch({
        type: 'user/SET_STATE',
        payload: payloadAction,
      })

      try {
        localStorage.setItem('user', JSON.stringify(payloadAction))
      } catch (e) {
        console.log(e)
      }
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen()
      }
    })
    this.UserManager.events.addSilentRenewError(e => {
      console.log('silent renew error', e.message)
    })

    this.UserManager.events.addAccessTokenExpired(() => {
      this.signinSilent()
    })
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      ''
    })
  }

  getUser = async () => {
    const user = await this.UserManager.getUser()
    if (!user) {
      // eslint-disable-next-line no-return-await
      return await this.UserManager.signinRedirectCallback()
    }
    return user
  }

  parseJwt = token => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname)
    this.UserManager.signinRedirect({})
  }

  navigateToScreen = () => {
    window.location.replace(`${process.env.REACT_APP_REDIRECT_PATH}`)
  }

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`,
      ),
    )

    return !!oidcStorage && !!oidcStorage.access_token
  }

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then(user => {
        console.log('signed in', user)
      })
      .catch(err => {
        console.log(err)
      })
  }

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback()
  }

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest()
  }

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    })
    this.UserManager.clearStaleState()
  }

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear()
      window.location.replace(`${process.env.REACT_APP_REDIRECT_PATH}`)
    })
    this.UserManager.clearStaleState()
  }
}
