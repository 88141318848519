import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
// eslint-disable-next-line no-unused-vars
import { PrivateRoute } from './components/Components/OidcComponent/route'
import { Callback } from './components/Components/OidcComponent/callback'
import { Logout } from './components/Components/OidcComponent/logout'
import { LogoutCallback } from './components/Components/OidcComponent/logoutCallback'
import { SilentRenew } from './components/Components/OidcComponent/silentRenew'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Dashboards
  {
    path: '/rbd',
    component: loadable(() => import('pages/awm')),
    exact: true,
  },
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/rbd" />} />
            <Route exact path="/signin-oidc" component={Callback} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/logout/callback" component={LogoutCallback} />
            <Route exact path="/silentrenew" component={SilentRenew} />
            {routes.map(route => (
              <PrivateRoute
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
