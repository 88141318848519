import React from 'react'
import styles from './style.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className="row">
        <div className="col-sm-12">
          <div className={styles.copyright}>
            <span>
              &copy; {new Date().getFullYear()}{' '}
              <a href="https://momentum-technologies.nl/" target="_blank" rel="noopener noreferrer">
                Momentum Technologies B.V.
              </a>{' '}
              All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
