import actions from './actions'

function getLocalStorageUser() {
  try {
    const json = JSON.parse(localStorage.getItem('user'))
    if (json) return json
  } catch (e) {
    console.log(e)
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      token: '',
      loading: false,
    }
  }
}

const initialState = getLocalStorageUser()

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
