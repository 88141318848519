import { actions } from './actions'

const initState = {
  graphData: {},
  objectPictureUrl: null,
  options: [],
  houseNumber: '',
  houseNumberAddition: '',
  postalCode: '',
  objectId: null,
  scenarioId: null,
  isCalculateDisabled: false,
  responseCodeHwk: '',
  responsePointEstimate: null,
  montecarlo: null,
  montecarloDone: true,
  fileUploaded: 1,
  object: {
    address: '',
  },
}

function awmReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_AWM_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export { awmReducer }
