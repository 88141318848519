
import React from "react";
import { OidcConsumer } from "./index";

export const Callback = () => (
  <OidcConsumer>
    {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return <span>loading</span>;
        }}
  </OidcConsumer>
);