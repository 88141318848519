import React from "react";
import { OidcConsumer } from "./index";

export const SilentRenew = () => (
  <OidcConsumer>
    {({ signinSilentCallback }) => {
            signinSilentCallback();
            return <span>loading</span>;
        }}
  </OidcConsumer>
);