import axios from "axios";

const getStreetViewImage = async (coordinates) => {
  try {
    const url = `https://maps.googleapis.com/maps/api/streetview?size=320x180&location=${coordinates[1]},${coordinates[0]}
      &fov=95&heading=70&pitch=0
      &key=${process.env.REACT_APP_GOOGLE_KEY}`
    const options = {
      method: 'GET',
      responseType: 'blob',
      url
    }
    const retVal = await axios(options).then(data => data).then(data => data.data)
    return retVal;
  } catch (e) {
    return '';
  }
}

export {getStreetViewImage}