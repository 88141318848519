import React from "react";
import { OidcConsumer } from "./index";

export const Logout = () => (
  <OidcConsumer>
    {({ logout }) => {
            logout();
            return <span>loading</span>;
        }}
  </OidcConsumer>
);