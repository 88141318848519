import React from 'react'
import { Route } from 'react-router-dom'
import { OidcConsumer } from './index'

export const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = Component => props => {
    return (
      <OidcConsumer>
        {({ isAuthenticated, signinRedirect }) => {
          if (!!Component && isAuthenticated()) {
            return <Component {...props} />
          }
          signinRedirect()
          return <span>loading</span>
        }}
      </OidcConsumer>
    )
  }

  return <Route {...rest} render={renderFn(component)} />
}
