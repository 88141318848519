import { message } from 'antd'
import axios from 'axios'
import { store } from '../../src/index'
import { actions } from '../redux/awm/actions'

const CancelToken = axios.CancelToken
let source = CancelToken.source()

const getToken = () => {
  try {
    const json = JSON.parse(localStorage.getItem('user'))
    if (json) return json.token
  } catch (e) {
    console.log(e)
  }
}

const getGraphDataPost = async (rentalValue, capitalizationFactor, objectId) => {
  try {
    if (rentalValue.length <= 0) rentalValue = 0
    if (capitalizationFactor.length <= 0) capitalizationFactor = 0
    const url = `${process.env.REACT_APP_API_BASE_URL}/awm/objecten/${objectId}/waarderingen/hwk`
    const body = {
      huurwaarde: parseFloat(rentalValue),
      kapitalisatiefactor: parseFloat(capitalizationFactor),
    }
    const retVal = await axios
      .post(url, body, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then(data => data.data.data)
    return retVal
  } catch (error) {
    return {
      status: 'success',
      data: {
        Waarde: 0.0,
        Standaardafwijking: 0.0,
        KansOpOverwaardering: 0.0,
        FrequentieVerdelings: [
          {
            Waarde: 0.0,
            Aantal: 1000.0,
          },
        ],
      },
    }
  }
}

const getGraphDataGet = async objectId => {
  try {
    let responseCode
    const url = `${process.env.REACT_APP_API_BASE_URL}/awm/objecten/${objectId}/waarderingen/hwk`
    const retVal = await axios
      .get(url, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then(data => {
        responseCode = data.status
        return data.data.data
      })
    return { retVal, responseCode }
  } catch (e) {
    return {
      status: 'success',
      data: {
        Waarde: 0.0,
        Standaardafwijking: 0.0,
        KansOpOverwaardering: 0.0,
        FrequentieVerdelings: [
          {
            Waarde: 0.0,
            Aantal: 1000.0,
          },
        ],
      },
    }
  }
}

const getSuggestion = async term => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/adressen/suggestie?zoekTerm=${term}`
    const retVal = await axios.get(url).then(data => data.data.data)
    return retVal
  } catch (e) {
    return []
  }
}

const getCoordinates = async id => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/adressen/zoek?id=${id}`
  const retVal = await axios.get(url).then(data => data.data.data)
  return retVal
}

const saveHouse = async payload => {
  try {
    if (!payload.type) {
      message.error('Wrong json format')
      return
    }
    const url = `${process.env.REACT_APP_API_BASE_URL}/awm/objecten?objectType=${payload.type}`
    delete payload.objectType
    const retVal = await axios
      .post(url, payload, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then(data => {
        message.success('Object is opgeslagen!')
        return data.data.data
      })
    return retVal
  } catch (e) {
    message.error('Upload mislukt')
    return null
  }
}

const postScenario = async payload => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/awm/scenario?object_id=${payload.objectId}`
    delete payload.objectId
    const retVal = await axios
      .post(url, payload, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then(data => data.data.data)
    return retVal
  } catch (e) {
    return null
  }
}

const postPointEstimate = async payload => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/awm/objecten/${payload.scenarioId}/waarderingen/awm`
    delete payload.objectType
    const retVal = await axios
      .post(url, payload, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then(data => data.data.data)
    return retVal
  } catch (e) {
    return null
  }
}

const postMonteCarlo = async payload => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/awm/simulation/${payload.scenarioId}/waarderingen/awm?number_of_calculation=${payload.numberOfCalculations}`
    delete payload.objectType
    const retVal = await axios
      .post(url, payload, { headers: { Authorization: `Bearer ${getToken()}` } })
      .then(data => {
        setTimeout(() => {
          getMonteCarlo(payload)
        }, 2500)
        return data.data.data
      })
    return retVal
  } catch (e) {
    return null
  }
}

const getMonteCarlo = async payload => {
  source = CancelToken.source()
  const url = `${process.env.REACT_APP_API_BASE_URL}/awm/simulation/${payload.scenarioId}/waarderingen/awm`
  const retVal = await axios
    .get(url, { cancelToken: source.token })
    .then(data => data)
    .catch(e => {
      if (e) {
        if (axios.isCancel(e)) {
          message.info('Berekening geannuleerd')
          store.dispatch({
            type: actions.SET_AWM_DATA,
            payload: {
              montecarloDone: true,
            },
          })
        } else if (e.response.status === 404) {
          getMonteCarlo(payload)
          return null
        }
      }
    })
  if (retVal) {
    if (retVal.status === 206) {
      store.dispatch({
        type: actions.SET_AWM_DATA,
        payload: {
          montecarlo: retVal.data.data,
        },
      })
      setTimeout(() => {
        getMonteCarlo(payload)
      }, 500)
      return retVal.data.data
    } else if (retVal.status === 200) {
      store.dispatch({
        type: actions.SET_AWM_DATA,
        payload: {
          montecarlo: retVal.data.data,
          montecarloDone: true,
        },
      })
      return retVal.data.data
    }
  } else {
    return null
  }
}

export {
  getGraphDataPost,
  getGraphDataGet,
  getSuggestion,
  getCoordinates,
  saveHouse,
  postPointEstimate,
  postScenario,
  postMonteCarlo,
  getMonteCarlo,
  source,
}
