import React, { Component } from 'react';
import {connect} from 'react-redux'

import AuthService from "../../../services/oAuth";

const OidcContext = React.createContext({
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    createSigninRequest: () => ({})
});

export const OidcConsumer = OidcContext.Consumer;

@connect()
class OidcComponent extends Component {

    // eslint-disable-next-line react/sort-comp
    authService;

    constructor(props) {
        super(props);
        this.authService = new AuthService(props);
    }

    render() {
        // eslint-disable-next-line react/destructuring-assignment
        return <OidcContext.Provider value={this.authService}>{this.props.children}</OidcContext.Provider>;
    }

}

export default OidcComponent;
