import React from "react";
import { OidcConsumer } from "./index";

export const LogoutCallback = () => (
  <OidcConsumer>
    {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return <span>loading</span>;
        }}
  </OidcConsumer>
);