const actions = {
  GET_GRAPH: 'awm/GET_GRAPH',
  SET_AWM_DATA: 'awm/SET_AWM_DATA',
  GET_STREET_VIEW_IMAGE: 'awm/GET_STREET_VIEW_IMAGE',
  GET_SUGGESTION: 'awm/GET_SUGGESTION',
  SAVE_OBJECT: 'awm/SAVE_OBJECT',
  GET_AWM_GRAPH_DATA: 'awm/GET_AWM_GRAPH_DATA',
  SAVE_GET_GRAPH: 'awm/SAVE_GET_GRAPH',
  GET_GRAPH_GET: 'awm/GET_GRAPH_GET',
  POST_POINT_ESTIMATE: 'awm/POST_POINT_ESTIMATE',
  POST_SCENARIO: 'awm/POST_SCENARIO',
  POST_MONTE_CARLO: 'awm/POST_MONTE_CARLO',
  SAVE_OBJECT_AWM: 'awm/SAVE_OBJECT_AWM',
  INCREMENT_FILE: 'awm/INCREMENT_FILE',
}

export { actions }
