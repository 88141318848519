import React from 'react'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import styles from './style.module.scss'

@withRouter
class LoginLayout extends React.PureComponent {
  state = {
    backgroundNumber: 1,
    backgroundEnabled: true,
  }

  // changeBackground = () => {
  //   const { backgroundNumber } = this.state
  //   this.setState({
  //     backgroundEnabled: true,
  //     backgroundNumber: backgroundNumber === 1,
  //   })
  // }

  toggleBackground = () => {
    const { backgroundEnabled } = this.state
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    })
  }

  render() {
    const { children } = this.props
    const { backgroundNumber, backgroundEnabled } = this.state

    return (
      <Layout>
        <Layout.Content>
          <div
            className={backgroundEnabled ? `${styles.layout} ${styles.light}` : `${styles.layout}`}
            style={{
              backgroundImage: backgroundEnabled
                ? `url('resources/images/photos/${backgroundNumber}.jpeg')`
                : `none`,
            }}
          >
            <div className={styles.header}>
              <div className={styles.logo}>
                <Link to="/">
                  {!backgroundEnabled && (
                    <img src="resources/images/logo.png" alt="Risk Based Decision support system" />
                  )}
                  {backgroundEnabled && (
                    <img
                      src="resources/images/logo-inverse.png"
                      alt="Risk Based Decision support system"
                    />
                  )}
                </Link>
              </div>
            </div>
            <div className={styles.content}>{children}</div>
            <div className={`${styles.footer} text-center`}>
              <p>
                &copy; {new Date().getFullYear()} Momentum Technologies B.V. All rights reserved.
              </p>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
