import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import { actions } from './actions'
import {
  getGraphDataPost as serviceGetGraphData,
  getSuggestion,
  getCoordinates,
  saveHouse,
  getGraphDataGet,
  postScenario,
  postPointEstimate,
  postMonteCarlo,
  getMonteCarlo,
} from '../../services/awm'
import { getStreetViewImage } from '../../services/streetViewImage'
import { getAwmData } from '../../services/awmMte'

function* getGraphData({ payload }) {
  const data = yield call(
    serviceGetGraphData,
    payload.rentalValue,
    payload.capitalizationFactor,
    payload.objectId,
  )
  yield put({
    type: actions.SET_AWM_DATA,
    payload: {
      graphData: data,
    },
  })
}

function* getGraphForInterval({ payload }) {
  const { retVal, responseCode } = yield call(getGraphDataGet, payload.objectId)
  yield put({
    type: actions.SET_AWM_DATA,
    payload: {
      graphData: retVal.waarderingen.hwk,
      responseCodeHwk: responseCode,
    },
  })
}

function* getStreetImage({ payload }) {
  const address = yield call(getCoordinates, payload.id)
  const data = yield call(getStreetViewImage, address.geolocation.centroid.coordinates)
  yield put({
    type: actions.SET_AWM_DATA,
    payload: {
      objectPictureUrl: data,
      houseNumber: address.houseNumber,
      houseNumberAddition: address.houseLetter,
      postalCode: address.postalCode,
    },
  })
}

function* suggestion({ payload }) {
  if (!payload.term || payload.term === '') return
  const data = yield call(getSuggestion, payload.term)
  yield put({
    type: actions.SET_AWM_DATA,
    payload: {
      options: data,
    },
  })
}

function* saveObject({ payload }) {
  // console.log('save object ')
  const data = yield call(saveHouse, payload)
  const fileUpload = yield select(state => {
    return state.awm.fileUploaded
  })
  if (data)
    yield put({
      type: actions.SET_AWM_DATA,
      payload: {
        objectId: data['_id'],
        fileUploaded: fileUpload + 1,
      },
    })
}

function* saveScenario({ payload }) {
  const data = yield call(postScenario, payload)
  yield put({
    type: actions.SET_AWM_DATA,
    payload: {
      scenarioId: data['_id'],
    },
  })
}

function* calculatePointEstimate({ payload }) {
  const data = yield call(postPointEstimate, payload)
  yield put({
    type: actions.SET_AWM_DATA,
    payload: {
      responsePointEstimate: data,
    },
  })
}

function* getAwmGraph({ payload }) {
  const data = yield call(getAwmData, payload.objectId)
}

function* postMonte({ payload }) {
  const data = yield call(postMonteCarlo, payload)

  // yield9 put({
  //   type: actions.SET_AWM_DATA,
  //   payload: {
  //     responsePointEstimate: data
  //   }
  // })
}

function* saveAwmObject({ payload }) {
  const data = yield call(saveHouse, payload)
  if (data)
    yield put({
      type: actions.SET_AWM_DATA,
      payload: {
        objectId: data['_id'],
      },
    })
}

function* rootSaga() {
  yield all([
    takeLatest(actions.GET_GRAPH, getGraphData),
    takeLatest(actions.GET_STREET_VIEW_IMAGE, getStreetImage),
    takeLatest(actions.GET_SUGGESTION, suggestion),
    takeLatest(actions.SAVE_OBJECT, saveObject),
    takeLatest(actions.GET_AWM_GRAPH_DATA, getAwmGraph),
    takeLatest(actions.GET_GRAPH_GET, getGraphForInterval),
    takeLatest(actions.POST_SCENARIO, saveScenario),
    takeLatest(actions.POST_POINT_ESTIMATE, calculatePointEstimate),
    takeLatest(actions.POST_MONTE_CARLO, postMonte),
    takeLatest(actions.SAVE_OBJECT_AWM, saveAwmObject),
  ])
}

export { rootSaga as awmSaga }
